
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './produtores.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadprodutores, setLoadProdutores] = useState(true);
  const [produtores, setProdutores] = useState([]);
  const [produtoresSelected, setProdutoresSelected] = useState(0);

  const [nomeprodutor, setNomeprodutor] = useState('');
  const [cidadeprodutor, setCidadeprodutor] = useState('');

  const [logradouroprodutor, setLogradouroprodutor] = useState('');
  const [numeroprodutor, setNumeroprodutor] = useState('');
  const [complementoprodutor, setComplementoprodutor] = useState('');
  const [gpsprodutor, setGpsprodutor] = useState('');

  const [categoriaprodutor, setCategoriaprodutor] = useState('');
  const [textosimplesprodutor, setTextosimplesprodutor] = useState('');
  const [textocompletoprodutor, setTextocompletoprodutor] = useState('');
  const [linkfbprodutor, setLinkfbprodutor] = useState('');
  const [linkinstagramprodutor, setLinkinstagramprodutor] = useState('');
  const [linksiteprodutor, setLinksiteprodutor] = useState('');
  const [telefoneprodutor, setTelefoneprodutor] = useState('');

  const [idProdutores, setIdProdutores] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {
    async function loadprodutores(){
      await firebase.firestore().collection('produtores')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomeprodutor: doc.data().nomeprodutor
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA produtor ENCONTRADA');
          setProdutores([ { id: '1', nomeprodutor: 'AVULSO' } ]);
          setLoadProdutores(false);
          return;
        }

        setProdutores(lista);
        setLoadProdutores(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadProdutores(false);
        setProdutores([ { id: '1', nomeprodutor: '' } ]);
      })

    }
    loadprodutores();
  }, [id]);


  async function loadId(lista){
    await firebase.firestore().collection('produtores').doc(id)
    .get()
    .then((snapshot) => {
      setNomeprodutor(snapshot.data().nomeprodutor);
      setCidadeprodutor(snapshot.data().cidadeprodutor);
      setLogradouroprodutor(snapshot.data().logradouroprodutor);
      setNumeroprodutor(snapshot.data().numeroprodutor);
      setComplementoprodutor(snapshot.data().complementoprodutor);
      setGpsprodutor(snapshot.data().gpsprodutor);
      setCategoriaprodutor(snapshot.data().categoriaprodutor);
      setTextosimplesprodutor(snapshot.data().textosimplesprodutor);
      setTextocompletoprodutor(snapshot.data().textocompletoprodutor);
      setLinkfbprodutor(snapshot.data().linkfbprodutor);
      setLinkinstagramprodutor(snapshot.data().linkinstagramprodutor);
      setLinksiteprodutor(snapshot.data().linksiteprodutor)
      setTelefoneprodutor(snapshot.data().telefoneprodutor)

      let index = lista.findIndex(item => item.id === snapshot.data().projetoId );
      setProdutoresSelected(index);
      setIdProdutores(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdProdutores(false);
    })
  }

  async function handleRegister(e){
    e.preventDefault();

    if(idProdutores){
      await firebase.firestore().collection('produtores')
      .doc(id)
      .update({
        nomeprodutor: nomeprodutor,
        cidadeprodutor: cidadeprodutor,
        logradouroprodutor: logradouroprodutor,
        numeroprodutor: numeroprodutor,
        complementoprodutor: complementoprodutor,
        gpsprodutor: gpsprodutor,
        categoriaprodutor: categoriaprodutor,
        textosimplesprodutor: textosimplesprodutor,
        textocompletoprodutor: textocompletoprodutor,
        linkfbprodutor: linkfbprodutor,
        linkinstagramprodutor: linkinstagramprodutor,
        linksiteprodutor: linksiteprodutor,
        telefoneprodutor: telefoneprodutor,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Produtor editada com sucesso!');
        setProdutoresSelected(0);
        setNomeprodutor('');
        history.push('/produtoreslistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }

    await firebase.firestore().collection('produtores')
    .add({
      created: new Date(),
      nomeprodutor: nomeprodutor,
      cidadeprodutor: cidadeprodutor,
      logradouroprodutor: logradouroprodutor,
      numeroprodutor:numeroprodutor,
      complementoprodutor: complementoprodutor,
      gpsprodutor: gpsprodutor,
      categoriaprodutor: categoriaprodutor,
      textosimplesprodutor: textosimplesprodutor,
      textocompletoprodutor: textocompletoprodutor,
      linkfbprodutor: linkfbprodutor,
      linkinstagramprodutor: linkinstagramprodutor,
      linksiteprodutor: linksiteprodutor,
      telefoneprodutor: telefoneprodutor,
      userId: user.uid
    })
    .then(()=> {
      toast.success('Produtor cadastrada com sucesso!');
      setNomeprodutor('');
      setCidadeprodutor('');
      setLogradouroprodutor('');
      setNumeroprodutor('');
      setComplementoprodutor('');
      setGpsprodutor('');
      setCategoriaprodutor('');
      setTextosimplesprodutor('');
      setTextocompletoprodutor('');
      setLinkfbprodutor('');
      setLinkinstagramprodutor('');
      setLinksiteprodutor('');
      setTelefoneprodutor('');
      setProdutoresSelected(0);
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })


  }


  //Chamado quando troca o assunto
  function handleChangeCategoria(e){
    setCategoriaprodutor(e.target.value);
  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Novo Produtor">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Nome</label>
            <input type="text" placeholder="Nome do produtor" value={nomeprodutor} onChange={ (e) => setNomeprodutor(e.target.value) } />

            <label>Cidade</label>
            <input type="text" placeholder="Cidade produtor" value={cidadeprodutor} onChange={ (e) => setCidadeprodutor(e.target.value) } />

            <label>Logradouro</label>
            <input type="text" placeholder="Logradouro" value={logradouroprodutor} onChange={ (e) => setLogradouroprodutor(e.target.value) } />

            <label>Número</label>
            <input type="text" placeholder="Número" value={numeroprodutor} onChange={ (e) => setNumeroprodutor(e.target.value) } />

            <label>Complemento</label>
            <input type="text" placeholder="Complemento" value={complementoprodutor} onChange={ (e) => setComplementoprodutor(e.target.value) } />

            <label>GPS</label>
            <input type="text" placeholder="GPS" value={gpsprodutor} onChange={ (e) => setGpsprodutor(e.target.value) } />

            <label>Categoria</label>
            <select value={categoriaprodutor} onChange={handleChangeCategoria}>
              <option value="Minas Artesanal">Minas Artesanal</option>
              <option value="Finos">Finos</option>
              <option value="Reino">Reino</option>
            </select>


            <label>Texto Simples - 100 caracteres</label>
            <textarea
              type="text"
              placeholder="texto simples"
              value={textosimplesprodutor}
              onChange={ (e) => setTextosimplesprodutor(e.target.value) }
            />

            <label>Texto Completo - 500 caracteres</label>
            <textarea
              type="text"
              placeholder="texto completo"
              value={textocompletoprodutor}
              onChange={ (e) => setTextocompletoprodutor(e.target.value) }
            />

            <label>Facebook</label>
            <input type="text" placeholder="link Facebook" value={linkfbprodutor} onChange={ (e) => setLinkfbprodutor(e.target.value) } />
            <label>Instagram</label>
            <input type="text" placeholder="link Instagram" value={linkinstagramprodutor} onChange={ (e) => setLinkinstagramprodutor(e.target.value) } />
            <label>Página</label>
            <input type="text" placeholder="link sem o http://" value={linksiteprodutor} onChange={ (e) => setLinksiteprodutor(e.target.value) } />
            <label>Telefone</label>
            <input type="text" placeholder="telefone(XX)XXXXX-XXXX" value={telefoneprodutor} onChange={ (e) => setTelefoneprodutor(e.target.value) } />

            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}