
import './site.css';
import { useState, useEffect } from 'react';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2, FiSettings } from 'react-icons/fi';
import { FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import firebase from '../../services/firebaseConnection';
import Modalprodutores from '../../components/Modalprodutores';


import logo from '../imagens/logorotasup.png';
import logopreta from '../imagens/logorotapreta.png';
import logotrilha from '../imagens/logotrilhasup.png';

import avatar from '../imagens/logotrilhasup.png';

import patrimoniominas from '../imagens/patrimoniominas.png';

import patrimonio1 from '../imagens/patrimonio1.jpg';
import patrimonio2 from '../imagens/patrimonio2.jpg';

import queijoartesanal from '../imagens/queijoartesanal.png';
import queijosfinos from '../imagens/queijosfinos.png';
import queijoreino from '../imagens/queijoreino.png';

const listRef = firebase.firestore().collection('produtores').orderBy('created', 'asc');
const listRef4 = firebase.firestore().collection('parceiros').orderBy('created', 'asc');

export default function Dashboard(){
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();

  const [loading4, setLoading4] = useState(true);
  const [loadingMore4, setLoadingMore4] = useState(false);
  const [isEmpty4, setIsEmpty4] = useState(false);
  const [lastDocs4, setLastDocs4] = useState();

  const [complemento, setComplemento] = useState();
  const [texto1, setTexto1] = useState();
  const [texto2, setTexto2] = useState();
  const [texto3, setTexto3] = useState();
  const [texto4, setTexto4] = useState();
  const [texto5, setTexto5] = useState();
  const [texto6, setTexto6] = useState();

  const [nomeconfig, setNomeconfig] = useState('');
  const [sloganconfig, setSloganconfig]= useState('');
  const [desccurtaconfig, setDesccurtaconfig] = useState('');
  const [palavrachaveconfig, setPalavrachaveconfig] = useState('');
  const [razaoconfig, setRazaoconfig] = useState('');
  const [cnpjconfig, setCnpjconfig] = useState('');
  const [cepconfig, setCepconfig] = useState('');
  const [logradouroconfig, setLogradouroconfig] = useState('');
  const [numeroconfig, setNumeroconfig] = useState('');
  const [complementoconfig, setComplementoconfig] = useState('');
  const [bairroconfig, setBairroconfig] = useState('');
  const [cidadeconfig, setCidadeconfig] = useState('');
  const [estadoconfig, setEstadoconfig] = useState('');
  const [paisconfig, setPaisconfig] = useState('');
  const [siteconfig, setSiteconfig] = useState('');
  const [emailconfig, setEmailconfig] = useState('');
  const [linkinstagramconfig, setLinkinstagramconfig] = useState('');
  const [linkfbconfig, setLinkfbconfig] = useState('');
  const [linkyoutubeconfig, setLinkyoutubeconfig] = useState('');
  const [linkdriveconfig, setLinkdriveconfig] = useState('');
  const [linklinkedconfig, setLinklinkedconfig] = useState('');
  const [linkapplestoreconfig, setLinkapplestoreconfig] = useState('');
  const [linkgoogleplayconfig, setLinkgoogleplayconfig] = useState('');
  const [telefone1config, setTelefone1config] = useState('');
  const [telefone2config, setTelefone2config] = useState('');
  const [whats1config, setWhats1config] = useState('');
  const [whats2config, setWhats2config] = useState('');
  
  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();

  const [produtores, setProdutores] = useState([]);
  const [parceiros, setParceiros] = useState([]);

  useEffect(()=> {

    async function loadTextos(){
      await firebase.firestore().collection('textos')
      .doc('rKXxZ16DQMvBZsXmbtRj')
      .get()
      .then((snapshot) => {
        setComplemento(snapshot.data().complemento);  
        setTexto1(snapshot.data().texto1);
        setTexto2(snapshot.data().texto2);
        setTexto3(snapshot.data().texto3);
        setTexto4(snapshot.data().texto4);
        setTexto5(snapshot.data().texto5);
        setTexto6(snapshot.data().texto6)
      })

    }
    loadTextos();

    async function loadConfig(){
      await firebase.firestore().collection('config')
      .doc('mmrYz8xHP0DaKEgWOmxB')
      .get()
      .then((snapshot) => {
        setNomeconfig(snapshot.data().nomeconfig);
        setSloganconfig(snapshot.data().sloganconfig);
        setDesccurtaconfig(snapshot.data().desccurtaconfig);
        setPalavrachaveconfig(snapshot.data().palavrachaveconfig);
        setRazaoconfig(snapshot.data().razaoconfig);
        setCnpjconfig(snapshot.data().cnpjconfig);
        setCepconfig(snapshot.data().cepconfig);
        setLogradouroconfig(snapshot.data().logradouroconfig);
        setNumeroconfig(snapshot.data().numeroconfig);
        setComplementoconfig(snapshot.data().complementoconfig);
        setBairroconfig(snapshot.data().bairroconfig);
        setCidadeconfig(snapshot.data().cidadeconfig);
        setEstadoconfig(snapshot.data().estadoconfig);
        setPaisconfig(snapshot.data().paisconfig);
        setSiteconfig(snapshot.data().siteconfig);
        setEmailconfig(snapshot.data().emailconfig);
        setLinkinstagramconfig(snapshot.data().linkinstagramconfig);
        setLinkfbconfig(snapshot.data().linkfbconfig);
        setLinkyoutubeconfig(snapshot.data().linkyoutubeconfig);
        setLinkdriveconfig(snapshot.data().linkdriveconfig);
        setLinklinkedconfig(snapshot.data().linklinkedconfig);
        setLinkapplestoreconfig(snapshot.data().linkapplestoreconfig);
        setLinkgoogleplayconfig(snapshot.data().linkgoogleplayconfig);
        setTelefone1config(snapshot.data().telefone1config);
        setTelefone2config(snapshot.data().telefone2config);
        setWhats1config(snapshot.data().whats1config);
        setWhats2config(snapshot.data().whats2config)
      })

    }
    loadConfig();


    async function loadProdutores(){
      await listRef
      .get()
      .then((snapshot) => {
        updateStateProdutores(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore(false);
      })
  
      setLoading(false);
    }
    loadProdutores();

    async function loadParceiros(){
      await listRef4.limit(5)
      .get()
      .then((snapshot) => {
        updateStateparceiros(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore4(false);
      })
  
      setLoading4(false);
    }
    loadParceiros();
    return () => {

    }
  }, []);


  async function updateStateProdutores(snapshot){
    const isCollectionEmpty = snapshot.size === 0;

    if(!isCollectionEmpty){
      let lista = [];

      snapshot.forEach((doc)=>{
        lista.push({
          id: doc.id,
          nomeprodutor: doc.data().nomeprodutor,
          cidadeprodutor: doc.data().cidadeprodutor,
          logradouroprodutor: doc.data().logradouroprodutor,
          numeroprodutor: doc.data().numeroprodutor,
          complementoprodutor: doc.data().complementoprodutor,
          gpsprodutor: doc.data().gpsprodutor,
          categoriaprodutor: doc.data().categoriaprodutor,
          textosimplesprodutor: doc.data().textosimplesprodutor,
          textocompletoprodutor: doc.data().textocompletoprodutor,
          linkfbprodutor: doc.data().linkfbprodutor,
          linkinstagramprodutor: doc.data().linkinstagramprodutor,
          linksiteprodutor: doc.data().linksiteprodutor,
          telefoneprodutor: doc.data().telefoneprodutor,
        })
      })

      const lastDoc = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setProdutores(produtores => [...produtores, ...lista]);
      setLastDocs(lastDoc);

    }else{
      setIsEmpty(true);
    }

    setLoadingMore(false);

  }

  async function updateStateparceiros(snapshot){
    const isCollectionEmpty4 = snapshot.size === 0;

    if(!isCollectionEmpty4){
      let lista4 = [];

      snapshot.forEach((doc)=>{
        lista4.push({
          id4: doc.id,
          avatarUrlparceiro: doc.data().avatarUrlparceiro,
          nomeparceiro: doc.data().nomeparceiro,
          linksiteparceiro: doc.data().linksiteparceiro,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc4 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      setParceiros(parceiros => [...parceiros, ...lista4]);
      setLastDocs4(lastDoc4);
    }else{
      setIsEmpty4(true);
    }
    setLoadingMore4(false);
  }
  
  function togglePostModal(item){
    setShowPostModal(!showPostModal) //trocando de true pra false
    setDetail(item);
  }
    return(
      <div>
      <div className="Topo">
      <div className="App-divesquerda">
          <div className="Toposapp">
            <img src={logotrilha} className="App-trilha"  alt="Trilha dos Inconfidentes" />
          </div>
        </div>
        <div className="App-divdireita">
          <img src={logo} className="App-logo" alt="Rota do Queijo" />
        </div>
      </div>
  
      <div className="Menu">
      &nbsp;&nbsp;&nbsp;
        <a href="#PatrimônioMineiro">Patrimônio Mineiro</a>&nbsp;&nbsp;&nbsp;
        <a href="#QueijoMinasArtesanal">Minas Artesanal</a>&nbsp;&nbsp;&nbsp;
        <a href="#QueijosFinos">Finos</a>&nbsp;&nbsp;&nbsp;
        <a href="#QueijosReino">Reino</a>&nbsp;&nbsp;&nbsp;
        <a href="#CidadesReceptivos">Cidades & Receptivos</a>&nbsp;&nbsp;&nbsp;
        <a href="#Parceiros">Parceiros</a>&nbsp;&nbsp;&nbsp;
        <a href="#Contato">Contato</a>&nbsp;&nbsp;&nbsp;
        <a href={linkfbconfig} target="_blank"><FaFacebookSquare /></a>&nbsp;&nbsp;&nbsp;
        <a href={linkinstagramconfig} target="_blank"><FaInstagram /></a>&nbsp;&nbsp;&nbsp;
      </div>
  
      <div className="Patrimonio" id="PatrimônioMineiro">
        <div className="App-divesquerdat">
        <img src={patrimoniominas} className="App-patrimoniominas"  alt="Minas Gerais" />
        </div>
        <div className="App-divdireitat">
        PATRIMÔNIO MINEIRO<br></br>Patrimônio imaterial do país desde 2008.
        </div>
      </div>
  
      <div className="Textopatrimonio">
        <div className="App-divesquerdab">
          {texto1}
        </div>
        <div className="App-divdireitab">
        <img src={patrimonio1} className="Imgqueijos"  alt="Queijo Artesanal" />
        </div>
      </div>
      <div className="Textopatrimonio">
        <div className="App-divesquerdab">
        <img src={patrimonio2} className="Imgqueijos"  alt="Queijos Finos" />
        </div>
        <div className="App-divdireitab">
        {texto2}
        </div>
      </div>
  
      <div className="Espaco">
      </div>
  
      <div className="Queijos" id="QueijoMinasArtesanal" >
        <div className="App-divesquerdab">
        <img src={queijoartesanal} className="Imgqueijos"  alt="Queijo Artesanal" />
        </div>
        <div className="App-divdireitab" id="QueijoMinasArtesanal">
        QUEIJO MINAS ARTESANAL<br></br>
        <br></br>
        <tbody>
                {produtores.map((item, index)=>{
                  if (item.categoriaprodutor==='Minas Artesanal'){
                  return(
                    <div>
                    <button className="action" onClick={ () => togglePostModal(item) }>
                        {item.nomeprodutor} ( {item.cidadeprodutor} )
                    </button>
                    </div>
                  )
                  }
                })
              }
        </tbody>
        </div>
      </div>
  
      <div className="Queijos" id="QueijosFinos">
        <div className="App-divesquerdab">
        QUEIJOS FINOS<br></br>
        <br></br>
        <tbody>
                {produtores.map((item, index)=>{
                  if (item.categoriaprodutor==="Finos"){
                  return(
                    <div>
                    <button className="action" onClick={ () => togglePostModal(item) }>
                        {item.nomeprodutor} ( {item.cidadeprodutor} )
                    </button>
                    </div>
                  )
                  }
                })
              }
        </tbody>
        </div>
        <div className="App-divdireitab">
        <img src={queijosfinos} className="Imgqueijos"  alt="Queijos Finos" />
        </div>
      </div>
  
      <div className="Queijos" id="QueijosReino">
        <div className="App-divesquerdab">
        <img src={queijoreino} className="Imgqueijos"  alt="Queijo do Reino" />
        </div>
        <div className="App-divdireitab">
        QUEIJO REINO<br></br>
        <br></br>
        <tbody>
                {produtores.map((item, index)=>{
                  if (item.categoriaprodutor==="Reino"){
                  return(
                    <div>
                    <button className="action" onClick={ () => togglePostModal(item) }>
                        {item.nomeprodutor} ( {item.cidadeprodutor} )
                    </button>
                    </div>
                  )
                  }
                })
              }
        </tbody>
        </div>
      </div>

      <div className="Espaco">
      </div>
  
      <div className="Cidades" id="CidadesReceptivos">
        CIDADES & RECEPTIVOS
      </div>
  
      <div className="Espaco">
      </div>
  
      <div className="Parceiros" id="Parceiros">
        PARCEIROS<br></br>
        <div style={{height: "100%", width: "100%", display: "flex", alignContent: "center", alignSelf: "center",   textalign: "center"}}>
        {parceiros.map((item, index)=>{
                  return(
        <div className="Parceiroscada">
          <a href={item.linksiteparceiro} target="_blank">
          { item.avatarUrlparceiro == null ? 
                <img src={avatar} width="auto" height="50" object-fit="scale-down" alt={item.nomeparceiro} />
                :
                <img src={item.avatarUrlparceiro} width="auto" height="50" object-fit="scale-down" alt={item.nomeparceiro} />
              }
          </a>
        </div>
            )
          })}
        </div>  
      </div>
  
      <div className="Espaco">
      </div>
  
      <div className="Rodape" id="Contato">
        <div style={{width: 150, height: 150, textAlign: "center", verticalAlign: "center",}}>
          <img src={logotrilha} className="App-trilha2"  alt="Trilha dos Inconfidentes" />
        </div>
        <div style={{width: 150, height: 150, textAlign: "left", margin: 5,}}>
          <a href="https://www.facebook.com/trilha.dosinconfidentes" target="_blank"><FaFacebookSquare /></a>&nbsp;&nbsp;
          <a href="https://www.instagram.com/tonatrilha/" target="_blank"><FaInstagram /></a><br></br>
          <a href="http://tonatrilha.tur.br/" target="_blank"><FaLaptop /></a><br></br>
          {telefone1config}<br></br>
          <a href="https://api.whatsapp.com/send?phone=5532999738011" target="_blank"><FaWhatsappSquare />&nbsp;&nbsp;Mariana</a><br></br>
          {telefone2config}<br></br>
          <a href="https://api.whatsapp.com/send?phone=5521996371207" target="_blank"><FaWhatsappSquare />&nbsp;&nbsp;Victória</a><br></br>
          <a mailto={emailconfig}>&nbsp;&nbsp;{emailconfig}</a><br></br>
        </div>
        <div style={{width: 400, height: 150, textAlign: "left", verticalAlign: "center",}}>
          {logradouroconfig}, {numeroconfig}<br></br>
          {complementoconfig},<br></br>
          {bairroconfig},<br></br>
          {cepconfig}<br></br>
          {cidadeconfig},<br></br>
          {estadoconfig}, {paisconfig}
        </div>
  
      <div style={{width: 150, height: 150, textAlign: "left", verticalAlign: "center",}}>
        <img src={logopreta} className="App-trilha"  alt="Rota do Queijo" />
      </div>
      <div style={{width: 150, height: 150, textAlign: "left", margin: 5,}}>
          <a href={linkfbconfig} target="_blank"><FaFacebookSquare /></a><br></br>
          <a href={linkinstagramconfig} target="_blank"><FaInstagram /></a>
      </div>
      </div>
      <div className="Tonatrilha">
        
      Todos os direitos reservados Tô na Trilha© {razaoconfig}, {cnpjconfig}
      <Link to="/login">
          <FiSettings color="#000" size={16} />
      </Link> 
      </div>
      {showPostModal && (
        <Modalprodutores
          conteudo={detail}
          close={togglePostModal}
        />
      )}
      </div>
  
  )
}