import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

let firebaseConfig = {
  apiKey: "AIzaSyATq40dD_cWvZAN2xn7JdbKlVC6psKgR40",
  authDomain: "rotadoqueijomg.firebaseapp.com",
  databaseURL: "https://rotadoqueijomg-default-rtdb.firebaseio.com",
  projectId: "rotadoqueijomg",
  storageBucket: "rotadoqueijomg.appspot.com",
  messagingSenderId: "584251350569",
  appId: "1:584251350569:web:83ad6bd582ef29b6974638",
  measurementId: "G-3QN94WS900"
};

if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}

export default firebase;